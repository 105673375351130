@import 'assets/scss/variables.scss';

.feeMessage {
  font-size: $xs;
  font-family: $font-family-regular;
  * {
    font-size: $xs;
    display: inline;
  }
}
