.toast {
  z-index: 999999;
  transition: transform 0.5s ease-in-out;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  > * {
    margin: 10px 10px 0;
    justify-content: flex-end;
  }
  &.topLeft {
    top: 0;
    left: 0;
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    > * {
      justify-content: flex-start;
    }
  }
  &.topCenter {
    top: 0;
    left: 50%;
    right: auto;
    transform: translate(-50%, 0);
    -webkit-animation-name: fadeInTop;
    animation-name: fadeInTop;
    > * {
      justify-content: center;
    }
  }
  &.topRight {
    top: 0;
    right: 0;
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }
  &.bottomLeft {
    bottom: 0;
    left: 0;
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    > * {
      justify-content: flex-start;
    }
  }
  &.bottomCenter {
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-animation-name: fadeInBottom;
    animation-name: fadeInBottom;
    > * {
      justify-content: center;
    }
  }
  &.bottomRight {
    bottom: 0;
    right: 0;
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }
  &.bottomLeft,
  &.bottomCenter,
  &.bottomRight {
    > * {
      margin: 0 10px 10px;
    }
  }
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(200px);
    -ms-transform: translateX(200px);
    transform: translateX(200px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(200px);
    -ms-transform: translateX(200px);
    transform: translateX(200px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-200px);
    -ms-transform: translateX(-200px);
    transform: translateX(-200px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-200px);
    -ms-transform: translateX(-200px);
    transform: translateX(-200px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeInTop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-200px);
    -ms-transform: translateY(-200px);
    transform: translateY(-200px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-200px);
    -ms-transform: translateY(-200px);
    transform: translateY(-200px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(200px);
    -ms-transform: translateY(200px);
    transform: translateY(200px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(200px);
    -ms-transform: translateY(200px);
    transform: translateY(200px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
