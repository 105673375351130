@import '../variables.scss';

@mixin focus-styling() {
  box-shadow: 0 0 0 1.5px #c000, inset 0 0 0 1.25px rgba(127, 82, 82, 0.5),
    0 13px 27px -5px rgba(93, 50, 50, 0.1), 0 8px 16px -8px rgba(0, 0, 0, 0.3),
    0 -6px 16px -6px rgba(0, 0, 0, 0.01);
}

@mixin primary-focus-styling() {
  box-shadow: 0 0 0 3px $btn-primary-focus,
    inset 0 0 0 1.25px rgba(127, 82, 82, 0.5),
    0 13px 27px -5px rgba(93, 50, 50, 0.1), 0 8px 16px -8px rgba(0, 0, 0, 0.3),
    0 -6px 16px -6px rgba(0, 0, 0, 0.01);
}

//border-button
@mixin btn-primary {
  border: 1px solid $btn-border;
  background: $link-color;
  transition: all 0.3s ease;
  &:hover {
    background: $white;
    color: $link-color;
  }
  &:disabled:hover {
    background: $link-color;
    color: $white;
  }
  &:focus {
    @include focus-styling();
  }
}
@mixin btn-outline-secondary {
  border: 1px solid $btn-border;
  transition: all 0.3s ease;
  &:hover {
    background: $link-color;
    color: $white;
  }

  &:focus {
    @include focus-styling();
  }
}
@mixin tertiary-btn {
  border: 1px solid $dark-gray;
  transition: all 0.3s ease;
  &:hover {
    background: $dark-gray;
    color: $white;
  }
}

@mixin btn-outline-quinary {
  border: 1px solid $success-color;
  transition: all 0.3s ease;
  &:hover {
    background: $success-color;
    color: $white;
  }
  &:focus {
    @include focus-styling();
  }
}

@mixin btn-styling {
  padding: 8px 27px;
  line-height: 1.5;
  font-size: $body-font;
  border-radius: $btn-border-radius;
  text-decoration: none;
  background: $white;
  align-items: center;
  cursor: pointer;

  span {
    padding-right: 6px;
  }
}

@mixin btn-senary {
  border: 1px solid $success-color;
  background: $success-color;
  transition: all 0.3s ease;
  &:hover {
    background: $white;
    color: $success-color;
  }
  &:disabled.keepOpacityOnDisabled {
    opacity: 1;
    background: $icon-green-disabled-color;
    border: 1px solid $icon-green-disabled-color;
    color: $white;
  }
}
