@import 'assets/scss/variables.scss';
@import 'assets/scss/mixins/utils.scss';
.fontFamilyMedium {
  font-family: $font-family-medium;
}

// TODO: Refactor Text.module.scss, uses same logic
.xs {
  @include variants($xs);
  @include decorators();
}
.sm {
  @include variants($sm);
  @include decorators();
}

.md {
  @include variants($md);
  @include decorators();
}

.lg {
  @include variants($lg);
  @include decorators();
}

.xlg {
  @include variants($xlg);
  @include decorators();
}

@include text-stylings();

.center {
  @include flex-center();
}
