@import './variables.scss';
.special-delivery-hdr-logo {
  border-bottom: 2px solid $hover-bg;
  padding-bottom: 10px;
  max-width: 180px;
  padding-left: 10px;
  padding-right: 10px;
}
.special-delivery-info-closer-container {
  display: none;
}

#divSpecialDeliveryOrderNumber {
  margin: 10px 10px;
  font-size: 16px;
  font-weight: bold;
}

#divSpecialDeliveryHeader {
  margin: 5px 10px;
  color: $light-gray;
}

.special-delivery-section-header {
  display: flex;
  padding: 7px 10px;
  background: $hover-bg;
  margin: 10px;
  color: $dark-gray;
  font-weight: bold;
  font-size: 16px;
}

#divServiceOptionHeader {
  width: 230px;
}

#deliveryIcon {
  margin-right: 10px;
}

.special-delivery-option-table {
  width: 100%;
}

.special-delivery-section {
  margin: 10px 15px;
}

.special-delivery-section-label {
  font-weight: bold;
}

.special-delivery-section tr td:last-child {
  color: $light-gray;
}

td.special-delivery-questions-label {
  font-weight: bold;
}
