.outer {
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  font-family: inherit;
  text-transform: none;
  font-weight: 600;
  user-select: none;
  white-space: nowrap;
  line-height: 1.2;
  font-size: 1.2rem;
  cursor: not-allowed;
  box-sizing: border-box;
  display: inline-block;
  border-width: 2px;
  text-rendering: optimizelegibility;
  border-color: currentcolor currentcolor transparent transparent;
  border-style: solid;
  border-radius: 9999px;
  color: currentcolor;
  animation: rotate-360 0.45s linear infinite;
  width: 1em;
  height: 1em;
  position: relative;
}

.inner {
  box-sizing: border-box;
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0px;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
}

@keyframes rotate-360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
