@font-face {
  font-family: MotivasanMedium;
  src: url('/static/fonts/motivasan/medium/MotivaSans-Medium.eot') format('eot'),
    url('/static/fonts/motivasan/medium/MotivaSans-Medium.woff2')
      format('woff2'),
    url('/static/fonts/motivasan/medium/MotivaSans-Medium.woff') format('woff'),
    url('/static/fonts/motivasan/medium/MotivaSans-Medium.ttf')
      format('truetype');
  font-display: swap;
}

@font-face {
  font-family: MotivasanRegular;
  src: url('/static/fonts/motivasan/regular/MotivaSans-Regular.eot')
      format('eot'),
    url('/static/fonts/motivasan/regular/MotivaSans-Regular.woff2')
      format('woff2'),
    url('/static/fonts/motivasan/regular/MotivaSans-Regular.woff')
      format('woff'),
    url('/static/fonts/motivasan/regular/MotivaSans-Regular.ttf')
      format('truetype');
  font-display: swap;
}

@font-face {
  font-family: MotivasanBold;
  src: url('/static/fonts/motivasan/bold/MotivaSans-Bold.eot') format('eot'),
    url('/static/fonts/motivasan/bold/MotivaSans-Bold.woff2') format('woff2'),
    url('/static/fonts/motivasan/bold/MotivaSans-Bold.woff') format('woff'),
    url('/static/fonts/motivasan/bold/MotivaSans-Bold.ttf') format('truetype');
  font-display: swap;
}
