@import 'assets/scss/variables.scss';

.specialDeliveryStyling {
  max-width: 300px;
  font-size: 11px;
}
.containerStyling {
  margin-left: 5px;
}

.iconColor {
  color: $icon-blue-color;
}
