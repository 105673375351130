@import 'assets/scss/mixins/breakpoints.scss';

@mixin apply-type($color, $background) {
  border: 1px solid $color;
  background-color: $background;
  .icon {
    font-size: 20px;
    color: $color;
  }
}

.alertWrapper {
  margin: 15px 10px;
  display: flex;
  margin-bottom: 0;
  justify-content: center;

  .alert {
    font-size: 11px;
    border-radius: 2px;
    padding: 13px;
    .alertText {
      span {
        color: $dark-gray;
      }
    }
    // types
    &.primary {
      @include apply-type($primary, $light-primary);
    }
    &.danger {
      @include apply-type($danger, $light-danger);
    }
    &.success {
      @include apply-type($success-color, $light-success);
    }
    &.warning {
      @include apply-type($warning, $light-warning);
    }

    // sizes
    &.md {
      width: 590px;
    }
    &.lg {
      width: 922px;
    }
    &.auto {
      width: auto;
    }

    .alertFlex {
      align-items: center;
      justify-content: space-between;
      display: flex;
      @include xs {
        align-items: flex-start;
      }
    }

    .alertContent {
      flex: auto;
    }

    .alertList {
      padding: 10px 20px 0;
      list-style-type: disc;
      font-size: $md;
    }

    @include xs {
      align-items: flex-start;
      padding: 10px;
    }

    .toggler {
      border: 0;
      background: 0;
      cursor: pointer;
      margin-left: 10px;
      > span {
        font-size: 9px;
        color: $dark-gray;
        padding: 5px 5px 0;
      }
      &:focus {
        border-radius: 0;
      }
    }

    .icon {
      padding: 0 8px 0 0;
      align-self: flex-start;
      font-size: 9px;
      &.center {
        align-self: center;
      }
    }
    a {
      padding-left: 2px;
    }
    &.md {
      width: 590px;
    }
    &.lg {
      width: 922px;
    }
    &.xl {
      width: 100%;
    }
    &.auto {
      width: auto;

      .buttonClose {
        min-height: 20px;
        align-self: flex-start;
        margin-left: 10px;
        span {
          padding-right: 0;
        }
      }

      .alertButton {
        display: inline-block;
        button {
          color: $link-color;
          text-decoration: underline;
        }
      }
    }
  }
  .buttonClose {
    span {
      padding-right: 0;
    }
    &:focus {
      border-radius: 0;
    }
  }
  .hideAlert {
    display: none;
  }
}
