.ccs-cc-ficons-items {
  display: flex;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  flex-flow: row !important;
}

.ccs-cc-inline-section {
  margin: 20px 0px !important;
}

.ccs-cc-inline-section-title {
  background: #eaecf5;
  padding: 8px 15px !important;
  background: #f5f5f5 !important;
}
