@font-face {
  font-family: 'icomoon';
  src: url('/static/fonts/iconmoon/icomoon.eot?eti8h3');
  src: url('/static/fonts/iconmoon/icomoon.eot?eti8h3#iefix')
      format('embedded-opentype'),
    url('/static/fonts/iconmoon/icomoon.ttf?eti8h3') format('truetype'),
    url('/static/fonts/iconmoon/icomoon.woff?eti8h3') format('woff'),
    url('/static/fonts/iconmoon/icomoon.svg?eti8h3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^='icon'],
[class*='icon'] {
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
