@import 'assets/scss/mixins/utils.scss';
.fontFamilyMedium {
  font-family: $font-family-medium;
}

// New simpler sizes - favor those instead
.xs {
  @include textVariants($xs);
  @include decorators();
}

.sm {
  @include textVariants($sm);
  @include decorators();
}

.md {
  @include textVariants($md);
  @include decorators();
}

.xLarge {
  @include textVariants($fsXl);
  @include decorators();
}

.lg {
  @include textVariants($lg);
  @include decorators();
}

.xlg {
  @include textVariants($xlg);
  @include decorators();
}

.xxlg {
  @include textVariants($xxLg);
  @include decorators();
}

.xxxlg {
  @include textVariants($fs2xl);
  @include decorators();
}

@include text-stylings();

.textAlignCenter {
  text-align: center;
}

//#   ---------------------------
//#   Text sizes (old style, with the added "Text" wording at the end of each)
//#   ---------------------------
.xsText {
  @include textVariants($xs);
  @include decorators();
}

.smText {
  @include textVariants($sm);
  @include decorators();
}

.mdText {
  @include textVariants($md);
  @include decorators();
}

.xLargeText {
  @include textVariants($fsXl);
  @include decorators();
}

.lgText {
  @include textVariants($lg);
  @include decorators();
}

.xlgText {
  @include textVariants($xlg);
  @include decorators();
}

.xxlgText {
  @include textVariants($xxLg);
  @include decorators();
}

.xxxlgText {
  @include textVariants($fs2xl);
  @include decorators();
}
