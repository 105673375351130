@import 'assets/scss/mixins/breakpoints.scss';

.slick-prev,
.slick-next {
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 50px;
  height: 50px;
  padding: 0;

  z-index: 1;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  @include md {
    width: 40px;
    height: 40px;
  }
  @include sm {
    height: 32px;
    width: 32px;
  }
  @include xs {
    height: 12px;
    width: 12px;
    line-height: 7px;
  }
}
.slick-prev {
  transform: translate(0, -50%);
}
.slick-next {
  transform: translate(0, -50%);
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'icomoon';
  font-size: 22px;

  color: $white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  @include md {
    font-size: 18px;
  }
  @include sm {
    font-size: 16px;
  }
  @include xs {
    font-size: 12px;
  }
}

.slick-prev {
  left: 0;
}
[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}
.slick-prev:before {
  content: '\e91f';
}
[dir='rtl'] .slick-prev:before {
  content: '→';
}

.slick-next {
  right: 0;
}
[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}
.slick-next:before {
  content: '\e91e';
}
[dir='rtl'] .slick-next:before {
  content: '←';
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: 35px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
  @include md {
    bottom: 28px;
  }
  @include sm {
    bottom: 26px;
  }
  &.primaryDots {
    bottom: -15px;
    @include xs {
      bottom: -18px;
    }
  }
}
.slick-dots li {
  position: relative;

  display: inline-block;

  width: 10px;
  height: 10px;
  margin: 0 3px;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 10px;
  height: 10px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
  padding: 4px;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 10px;
  height: 10px;

  text-align: center;

  background: $white;
  border-radius: 50%;
  border: 1px solid $dark-gray;
  content: '';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  background: $dark-gray;
  width: 11px;
  height: 11px;
}

.primaryDots.slick-dots li button:before {
  border: 1px solid $link-color;
}
.primaryDots.slick-dots li.slick-active button:before {
  background: $link-color;
}

/* Slider */
.slick-slider {
  position: relative;
  height: 100%;
  display: block;
  box-sizing: border-box;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  &.slickMain {
    .slick-next {
      right: 10px;
    }
    .slick-prev {
      left: 10px;
    }
    .slick-prev,
    .slick-next {
      @include sm {
        height: 18px;
        width: 18px;
        &:before,
        &:after {
          font-size: 8px;
        }
      }
    }
  }
}

.slick-list {
  position: relative;
  max-height: 100%;
  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  min-height: 1px;
}

.slick-slide > div {
  height: 100%;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
