@import 'assets/scss/mixins/mixins-button.scss';
@import 'assets/scss/mixins/breakpoints.scss';
@import 'assets/scss/mixins/utils.scss';

.btn {
  transition: all 0.3s ease-in-out;
  max-height: 37px;
  font-family: $font-family-medium;
  @include btn-styling();
  // disabled everywhere
  &:disabled {
    @include disabled-styles();

    // make it look disabled, but non-transparent
    &.keepOpacityOnDisabled {
      opacity: 1;
      background: $link-color-disabled;
      border: 1px solid $link-color-disabled;
    }
  }
}

.maxHeightNone {
  max-height: none;
}

.focusStylePrimary {
  outline: 0;
  box-shadow: 0 0 0 3px $btn-primary-focus,
    inset 0 0 0 1.25px rgba(127, 82, 82, 0.5),
    0 13px 27px -5px rgba(93, 50, 50, 0.1), 0 8px 16px -8px rgba(0, 0, 0, 0.3),
    0 -6px 16px -6px rgba(0, 0, 0, 0.01);
}

button.primary:focus,
button.secondary:focus,
button.quaternary:focus,
a.primary:focus,
a.secondary:focus {
  @extend .focusStylePrimary;
}
button.senary:focus,
button.quinary:focus {
  box-shadow: 0 0 0 3px $btn-senary-focus,
    inset 0 0 0 1.25px rgba(68, 105, 105, 0.5),
    0 13px 27px -5px rgba(44, 75, 75, 0.1), 0 8px 16px -8px rgba(0, 0, 0, 0.3),
    0 -6px 16px -6px rgba(0, 0, 0, 0.01);
}

.inlineFlexAndCenter {
  display: inline-flex;
  justify-content: center;
}

.primary {
  color: $white;
  @include btn-primary();
}

.small {
  margin: 0 5px;
  padding: 10px;
  border-radius: 35px;
}

.xsButton {
  margin: 0 5px;
  padding: 5px;
  border-radius: 35px;
  @include xs {
    margin: 5px 0 0 0;
  }
}

// Is basically the inverse state of primary, to refactor
.outline-primary {
  color: $link-color;
  @include btn-primary();
  background-color: transparent;
  &:hover {
    color: $white;
    background-color: $link-color;
  }
}

.secondary {
  color: $link-color;
  @include btn-outline-secondary();
}
.tertiary {
  @include tertiary-btn();
}

.link {
  max-height: none;
  padding: 0;
  border: 0;
  background: transparent;
  display: inline-flex;
  &:focus {
    border-radius: 0;
  }
}

.textDecoration {
  text-decoration: underline;
}

.redLink {
  composes: textDecoration;
  composes: link;
  color: $link-color;
  &:focus {
    border-radius: 0;
    box-shadow: 0 0 0 1px $link-color inset;
  }
}
.lightGrayLink {
  composes: textDecoration;
  composes: link;
  color: $light-gray;
}

.blueLink {
  composes: textDecoration;
  composes: link;
  color: $icon-blue-color;
}

.darkGrayLink {
  composes: textDecoration;
  composes: link;
  color: $dark-gray;
}
.successLink {
  composes: textDecoration;
  composes: link;
  color: $success-color;
}

.white {
  background: $white;
  border-radius: 3px;
  max-height: 36px;
  margin-top: 12px;
  margin-left: 20px;
  padding: 8px 34px;
  @include md {
    margin-left: 10px;
    padding: 8px 11px;
  }
}

.light {
  padding: 8px 10px;
  border: 1px solid $border-color;
}

.quinary {
  color: $success-color;
  @include btn-outline-quinary();
}

.senary {
  color: $white;
  @include btn-senary();
}

.quaternary {
  color: $link-color;
  @include btn-outline-secondary();
  padding: 7px 15px 7px 50px;
  position: relative;
  &::after {
    position: absolute;
    left: 40px;
    border-left: 1px solid $link-color;
    content: '';
    height: 100%;
    top: 0;
  }
  &:hover {
    &::after {
      border-left: 1px solid $white;
    }
  }
  span {
    position: absolute;
    left: 15px;
    padding-right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.error {
  border: 1px solid $link-color;
  background: rgba(204, 0, 0, 0.1);
  border-radius: 3px;
}

.rg {
  font-family: $font-family-regular;
}

.notAllowedCursor {
  cursor: not-allowed;
}

.noWrap {
  white-space: nowrap;
}

.redColor {
  color: $link-color;
}

.fullWidth {
  width: 100%;
}
.cursorDefault {
  cursor: default;
}

.borderRadiusRightTypeStraight {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
