@import 'assets/scss/variables.scss';
@import 'assets/scss/mixins/utils.scss';
.tooltip {
  padding: 8px 6px 7px;
  border: 0.25px solid $border-color;
  border-radius: 3px;
  background-color: $tooltip-bg;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 1px 2px 1px $tooltip-shadow-color;
  font-family: $font-family-regular;
  color: $light-gray;

  &.tooltipIe {
    @include ie {
      position: absolute;
      left: -60px !important;
      top: -65px !important;
    }
  }

  &:after {
    border-bottom-width: 0;
  }
}
